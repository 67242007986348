import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import { setMessages } from '../redux/slices/panelSlice';
import { fetchMessages, sendMessage } from '../api';
import {
  X,
  ZoomIn,
  ZoomOut,
  Maximize2,
  Minimize2,
  SunMedium,
  Loader2,
  Send,
  Paperclip,
} from 'lucide-react';

const urlRegex = /(https?:\/\/[^\s]+)/g;

const MessageText = ({ text }) => {
  const parts = text.split(urlRegex);

  return (
    <span className="inline-block break-words">
      {parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {part}
            </a>
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </span>
  );
};

const ImageViewer = ({ image, onClose, index }) => {
  const [zoom, setZoom] = useState(1.5);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [contrast, setContrast] = useState(100);
  const [brightness, setBrightness] = useState(100);
  const [sharpen, setSharpen] = useState(0);
  const [showEnhanceControls, setShowEnhanceControls] = useState(false);
  const imageRef = useRef(null);
  const [, setNaturalDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (imageRef.current) {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${image}`;
      img.onload = () => {
        setNaturalDimensions({
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [image]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === '+' || (e.ctrlKey && e.key === '=')) {
        e.preventDefault();
        setZoom((prev) => Math.min(prev + 0.25, 4));
      } else if (e.key === '-') {
        e.preventDefault();
        setZoom((prev) => Math.max(prev - 0.25, 0.5));
      } else if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onClose]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition((prev) => ({
        x: prev.x + e.movementX / zoom,
        y: prev.y + e.movementY / zoom,
      }));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const resetEnhancements = () => {
    setContrast(100);
    setBrightness(100);
    setSharpen(0);
  };

  return (
    <Rnd
      default={{
        width: Math.min(1200, window.innerWidth - 100),
        height: Math.min(900, window.innerHeight - 100),
        x: 50 + index * 30,
        y: 50 + index * 30,
      }}
      size={
        isFullscreen
          ? {
              width: window.innerWidth - 40,
              height: window.innerHeight - 40,
            }
          : undefined
      }
      position={isFullscreen ? { x: 20, y: 20 } : undefined}
      minWidth={600}
      minHeight={400}
      bounds="window"
      className="z-50"
      disableDragging={isFullscreen}
    >
      <div className="h-full w-full flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-between items-center p-3 border-b bg-gray-50">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <button
                onClick={() => setZoom((prev) => Math.max(prev - 0.25, 0.5))}
                className="p-1.5 hover:bg-gray-200 rounded-lg transition-colors"
                title="Zoom Out (-)"
              >
                <ZoomOut className="w-5 h-5 text-gray-600" />
              </button>
              <span className="text-sm text-gray-600 min-w-[60px]">
                {Math.round(zoom * 100)}%
              </span>
              <button
                onClick={() => setZoom((prev) => Math.min(prev + 0.25, 4))}
                className="p-1.5 hover:bg-gray-200 rounded-lg transition-colors"
                title="Zoom In (+)"
              >
                <ZoomIn className="w-5 h-5 text-gray-600" />
              </button>
            </div>
            <div className="h-6 w-px bg-gray-300" />
            <button
              onClick={() => setShowEnhanceControls(!showEnhanceControls)}
              className={`p-1.5 rounded-lg transition-colors ${
                showEnhanceControls
                  ? 'bg-blue-100 text-blue-600'
                  : 'hover:bg-gray-200 text-gray-600'
              }`}
              title="Image Enhancement Controls"
            >
              <SunMedium className="w-5 h-5" />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                setIsFullscreen(!isFullscreen);
                setPosition({ x: 0, y: 0 });
              }}
              className="p-1.5 hover:bg-gray-200 rounded-lg transition-colors"
            >
              {isFullscreen ? (
                <Minimize2 className="w-5 h-5 text-gray-600" />
              ) : (
                <Maximize2 className="w-5 h-5 text-gray-600" />
              )}
            </button>
            <button
              onClick={onClose}
              className="p-1.5 hover:bg-gray-200 rounded-lg transition-colors"
            >
              <X className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>

        {showEnhanceControls && (
          <div className="p-3 border-b bg-gray-50">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <label className="text-sm text-gray-600">Contrast:</label>
                <input
                  type="range"
                  min="50"
                  max="150"
                  value={contrast}
                  onChange={(e) => setContrast(Number(e.target.value))}
                  className="w-48"
                />
                <span className="text-sm text-gray-600 min-w-[40px]">
                  {contrast}%
                </span>
              </div>
              <div className="flex items-center justify-between">
                <label className="text-sm text-gray-600">Brightness:</label>
                <input
                  type="range"
                  min="50"
                  max="150"
                  value={brightness}
                  onChange={(e) => setBrightness(Number(e.target.value))}
                  className="w-48"
                />
                <span className="text-sm text-gray-600 min-w-[40px]">
                  {brightness}%
                </span>
              </div>
              <div className="flex items-center justify-between">
                <label className="text-sm text-gray-600">Sharpen:</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={sharpen}
                  onChange={(e) => setSharpen(Number(e.target.value))}
                  className="w-48"
                />
                <span className="text-sm text-gray-600 min-w-[40px]">
                  {Math.round(sharpen * 100)}%
                </span>
              </div>
              <button
                onClick={resetEnhancements}
                className="text-sm text-blue-600 hover:text-blue-700 mt-1"
              >
                Reset to Default
              </button>
            </div>
          </div>
        )}

        <div className="flex-1 flex overflow-hidden">
          <div className="relative w-full overflow-hidden bg-gray-100">
            <div
              className="absolute inset-0 flex items-center justify-center"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              style={{
                cursor: isDragging ? 'grabbing' : 'grab',
              }}
            >
              <div
                className="transition-transform duration-200 ease-in-out"
                style={{
                  transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                  transformOrigin: 'center',
                  willChange: 'transform',
                }}
              >
                <img
                  ref={imageRef}
                  src={`data:image/jpeg;base64,${image}`}
                  alt="Enlarged view"
                  className="max-w-none object-contain"
                  style={{
                    imageRendering: 'crisp-edges',
                    filter: `contrast(${contrast}%) brightness(${brightness}%) ${
                      sharpen > 0
                        ? `saturate(100%) opacity(100%) drop-shadow(0 0 ${sharpen}px rgba(0, 0, 0, 0.5))`
                        : ''
                    }`,
                    maxHeight: isFullscreen ? '90vh' : '70vh',
                  }}
                  draggable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Rnd>
  );
};

const MessagesPanel = ({ clientId, clientName, isOpen, onToggle }) => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.panels);
  const [newMessage, setNewMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const currentUser = useSelector((state) => state.auth.user);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const messageListRef = useRef(null);
  const [openImages, setOpenImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetchMessages(clientId)
        .then((data) => {
          dispatch(setMessages(data));
        })
        .catch((error) => {
          console.error('Error fetching messages:', error);
        })
        .finally(() => {
          setIsLoading(false);
          // Scroll to bottom after loading messages
          setTimeout(() => {
            if (messageListRef.current) {
              messageListRef.current.scrollTop =
                messageListRef.current.scrollHeight;
            }
          }, 100);
        });
    }
  }, [isOpen, clientId, dispatch]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    try {
      const text = newMessage.trim() || '';
      const sentMessage = await sendMessage(
        clientId,
        text,
        currentUser.id,
        selectedImage,
      );
      dispatch(setMessages([...messages, sentMessage]));
      setNewMessage('');
      setSelectedImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleImageClick = (image) => {
    setOpenImages([...openImages, image]);
  };

  const handleCloseImage = (index) => {
    setOpenImages(openImages.filter((_, i) => i !== index));
  };

  if (!isOpen) return null;

  const renderMessage = (message) => {
    const isCurrentUser = message.sender === currentUser.id;
    const senderName = isCurrentUser ? 'You' : clientName;

    return (
      <div
        key={message.id}
        className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'} mb-4`}
      >
        <div
          className={`max-w-[80%] rounded-lg px-4 py-2 ${
            isCurrentUser ? 'bg-blue-100' : 'bg-gray-100'
          }`}
        >
          <div
            className={`text-sm font-semibold ${
              isCurrentUser ? 'text-blue-800' : 'text-gray-800'
            }`}
          >
            {senderName}
          </div>
          {message.text && (
            <div className="mt-1 text-gray-700">
              <MessageText text={message.text} />
            </div>
          )}
          {message.image && (
            <div
              className="mt-2 cursor-pointer"
              onClick={() => handleImageClick(message.image)}
            >
              <img
                src={`data:image/jpeg;base64,${message.image}`}
                alt="Message attachment"
                className="max-w-full max-h-48 object-contain rounded-lg"
              />
            </div>
          )}
          <div className="mt-1 text-xs text-gray-500">
            {new Date(message.timestamp).toLocaleString()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Rnd
        default={{
          x: window.innerWidth / 2 - 200,
          y: window.innerHeight / 2 - 250,
          width: 400,
          height: 500,
        }}
        minWidth={300}
        minHeight={400}
        bounds="window"
        className="z-40"
      >
        <div className="flex flex-col h-full bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header */}
          <div className="flex justify-between items-center px-4 py-3 border-b bg-white">
            <h2 className="text-lg font-semibold text-gray-800">
              Messages with {clientName}
            </h2>
            <button
              onClick={onToggle}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>

          {/* Messages List */}
          <div
            ref={messageListRef}
            className="flex-1 overflow-y-auto p-4 flex flex-col-reverse"
          >
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              </div>
            ) : (
              <div>
                {messages.map(renderMessage)}
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>

          {/* Input Area */}
          <div className="p-4 border-t bg-gray-50">
            <div className="flex items-center gap-2 mb-2">
              <input
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                onKeyPress={(e) => {
                  if (
                    e.key === 'Enter' &&
                    (newMessage.trim() || selectedImage)
                  ) {
                    handleSendMessage();
                  }
                }}
              />
              <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={fileInputRef}
                onChange={handleImageSelect}
              />
              <button
                onClick={() => fileInputRef.current.click()}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <Paperclip className="w-5 h-5 text-gray-500" />
              </button>
            </div>

            {selectedImage && (
              <div className="text-sm text-gray-600 mb-2">
                Selected image: {selectedImage.name}
              </div>
            )}

            <button
              onClick={handleSendMessage}
              disabled={!newMessage.trim() && !selectedImage}
              className="w-full py-2 bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed text-white rounded-lg flex items-center justify-center gap-2 transition-colors"
            >
              <Send className="w-4 h-4" />
              Send
            </button>
          </div>
        </div>
      </Rnd>

      {/* Image Viewers */}
      {openImages.map((image, index) => (
        <ImageViewer
          key={index}
          image={image}
          onClose={() => handleCloseImage(index)}
          index={index}
        />
      ))}
    </>
  );
};

export default MessagesPanel;
