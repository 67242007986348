import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../axiosConfig';
import { Rnd } from 'react-rnd';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './dropdown';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import EditWorkoutPlanPopup from './EditWorkoutPlanPopup';
import WorkoutBuilder from './WorkoutBuilder';
import WorkoutPlanLibrary from './WorkoutPlanLibrary';

const WorkoutPlanPopup = ({ plan, onClose, onEdit }) => {
  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 400,
        height: 600,
      }}
      minWidth={300}
      minHeight={300}
      bounds="window"
      className="fixed z-50 bg-white shadow-lg rounded-lg overflow-hidden"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b cursor-move drag-handle">
          <h2 className="text-xl font-bold">{plan.title}</h2>
          <div>
            <button
              onClick={() => onEdit(plan.id)}
              className="mr-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Edit
            </button>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
          </div>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          <p className="mb-4">{plan.description}</p>
          {plan.sessions &&
            plan.sessions.map((session) => (
              <div key={session.id} className="mb-4 bg-gray-100 p-4 rounded-lg">
                <h3 className="text-lg font-medium">
                  Day {session.day}: {session.coaches_intro_note}
                </h3>
                <p>Time: {session.approx_time}</p>
                <p>Difficulty: {session.difficulty_level}</p>
                <h4 className="font-medium mt-2">Movements:</h4>
                <ul className="list-disc list-inside">
                  {session.movements.map((movement) => (
                    <li key={movement.id}>
                      {movement.title} - {movement.prescribed_sets} sets of{' '}
                      {movement.prescribed_reps} reps
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </Rnd>
  );
};

const WorkoutPlanBuilder = ({ clientId }) => {
  console.log('WorkoutPlanBuilder clientId:', clientId);
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [openPopups, setOpenPopups] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPlan, setEditingPlan] = useState(null);
  const [isCreatingPlan, setIsCreatingPlan] = useState(false);
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const [libraryMode, setLibraryMode] = useState('assign'); // 'assign' or 'edit'
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    fetchWorkoutPlans();
  }, [clientId]);

  const fetchWorkoutPlans = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/cd-workout/', {
        params: { client_id: clientId },
      });
      setWorkoutPlans(response.data);
    } catch (err) {
      setError('Failed to fetch workout plans. Please try again.');
      console.error('Error fetching workout plans:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionSelect = (action, planId) => {
    switch (action) {
      case 'view':
        setOpenPopups((prev) => ({ ...prev, [planId]: true }));
        break;
      case 'edit':
        const planToEdit = workoutPlans.find((plan) => plan.id === planId);
        setEditingPlan(planToEdit);
        setOpenPopups({}); // Close all view popups
        break;
      case 'delete':
        handleDeletePlan(planId);
        break;
      default:
        console.log('Unknown action', action);
    }
  };

  const handleDeletePlan = async (planId) => {
    if (
      window.confirm(
        'Are you sure you want to remove this workout plan from the client?',
      )
    ) {
      try {
        console.log('Removing plan:', planId, 'for client:', clientId); // Debug log

        const response = await axiosInstance.post(
          `/cd-workout/${planId}/remove_from_client/`,
          {
            client_id: clientId,
          },
        );

        console.log('Response:', response.data); // Debug log

        if (response.status === 200) {
          setWorkoutPlans((prevPlans) =>
            prevPlans.filter((plan) => plan.id !== planId),
          );
        } else {
          console.error('Unexpected response:', response);
          alert('An unexpected error occurred. Please try again.');
        }
      } catch (err) {
        console.error(
          'Error removing workout plan from client:',
          err.response?.data || err.message,
        );
        if (err.response?.status === 404) {
          alert(
            'The workout plan or client could not be found. Please refresh and try again.',
          );
        } else {
          alert(
            'An error occurred while trying to remove the workout plan. Please try again.',
          );
        }
      }
    }
  };

  const closePopup = (planId) => {
    setOpenPopups((prev) => ({ ...prev, [planId]: false }));
  };

  const closeEditPopup = () => {
    setEditingPlan(null);
  };

  const handleSaveEdit = async (editedPlan) => {
    try {
      const response = await axiosInstance.put(
        `/workout-plans/${editedPlan.id}/`,
        editedPlan,
      );

      if (response.status === 200) {
        setWorkoutPlans((prevPlans) =>
          prevPlans.map((plan) =>
            plan.id === editedPlan.id ? response.data : plan,
          ),
        );
        setEditingPlan(null);
      } else {
        console.error('Unexpected response:', response);
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error saving edited workout plan:', err);
      throw err;
    }
  };

  const handleCreateNewPlan = (option) => {
    if (option === 'fromScratch') {
      setIsCreatingPlan(true);
      setSelectedTemplate(null);
    } else if (option === 'fromTemplate') {
      setLibraryMode('create');
      setIsLibraryOpen(true);
    }
  };

  const handleSaveNewPlan = async (newPlan) => {
    try {
      console.log('Received in parent:', JSON.stringify(newPlan, null, 2));

      // Strip IDs from sessions and movements before sending
      const cleanedPlan = {
        title: newPlan.title,
        description: newPlan.description,
        sessions: newPlan.sessions.map((session) => {
          // Create a new object without the id
          const { id, ...sessionWithoutId } = session;
          return {
            ...sessionWithoutId,
            movements: session.movements.map((movement) => {
              // Create a new object without the id
              const { id, ...movementWithoutId } = movement;
              return movementWithoutId;
            }),
          };
        }),
      };

      console.log(
        'Sending to API:',
        JSON.stringify(
          {
            client_id: clientId,
            plan: cleanedPlan,
          },
          null,
          2,
        ),
      );

      const response = await axiosInstance.post('/cd-workout/', {
        client_id: clientId,
        plan: cleanedPlan,
      });

      console.log('API Response:', JSON.stringify(response.data, null, 2));

      setWorkoutPlans((prevPlans) => [...prevPlans, response.data]);
      setIsCreatingPlan(false);
      setSelectedTemplate(null);
    } catch (err) {
      console.error('Error creating new workout plan:', err);
    }
  };

  const handleOpenLibrary = () => {
    setLibraryMode('assign');
    setIsLibraryOpen(true);
  };

  const handleCloseLibrary = () => {
    setIsLibraryOpen(false);
  };

  const handleAssignFromLibrary = () => {
    fetchWorkoutPlans();
    setIsLibraryOpen(false);
  };

  const handleCreateFromTemplate = (templatePlan) => {
    setSelectedTemplate(templatePlan);
    setIsCreatingPlan(true);
    setIsLibraryOpen(false);
  };

  if (isLoading) return <div>Loading workout plans...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="workout-plan-builder">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Workout Plans</h2>
        <div className="flex space-x-2">
          <Dropdown>
            <DropdownButton className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              <PlusIcon className="h-5 w-5 mr-2" />
              Create New Plan
              <ChevronDownIcon className="h-5 w-5 ml-2" />
            </DropdownButton>
            <DropdownMenu>
              <DropdownItem onClick={() => handleCreateNewPlan('fromScratch')}>
                Create from Scratch
              </DropdownItem>
              <DropdownItem onClick={() => handleCreateNewPlan('fromTemplate')}>
                Build from Template
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <button
            onClick={handleOpenLibrary}
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Assign from Library
          </button>
        </div>
      </div>
      {workoutPlans.length === 0 ? (
        <p>No workout plans found for this client.</p>
      ) : (
        <ul className="space-y-4">
          {workoutPlans.map((plan) => (
            <li key={plan.id} className="border rounded-lg p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold">{plan.title}</h3>
                <Dropdown>
                  <DropdownButton outline>
                    Options <ChevronDownIcon className="h-5 w-5 ml-2" />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => handleActionSelect('view', plan.id)}
                    >
                      View
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleActionSelect('edit', plan.id)}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleActionSelect('delete', plan.id)}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <p className="text-gray-600 mt-2">{plan.description}</p>
              {openPopups[plan.id] && (
                <WorkoutPlanPopup
                  plan={plan}
                  onClose={() => closePopup(plan.id)}
                  onEdit={() => handleActionSelect('edit', plan.id)}
                />
              )}
            </li>
          ))}
        </ul>
      )}
      {isLibraryOpen && (
        <WorkoutPlanLibrary
          clientId={clientId}
          onClose={handleCloseLibrary}
          onAssign={handleAssignFromLibrary}
          onCreate={handleCreateFromTemplate}
          mode={libraryMode}
        />
      )}
      {editingPlan && (
        <EditWorkoutPlanPopup
          plan={editingPlan}
          onClose={closeEditPopup}
          onSave={handleSaveEdit}
          clientId={clientId}
        />
      )}
      {isCreatingPlan && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-4xl max-h-screen overflow-y-auto">
            <WorkoutBuilder
              onSave={handleSaveNewPlan}
              onCancel={() => {
                setIsCreatingPlan(false);
                setSelectedTemplate(null);
              }}
              clientId={clientId}
              template={selectedTemplate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkoutPlanBuilder;
