import React, { useState, useCallback } from 'react';

const AddNewFilterModal = ({ isOpen, onClose, onAddFilter }) => {
  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const MAX_FILTER_NAME_LENGTH = 50;

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (filterName.trim()) {
      setIsLoading(true);
      setError(null);
      try {
        await onAddFilter({ name: filterName.trim() });
        setFilterName('');
        onClose();
      } catch (err) {
        console.error('Error creating filter:', err);
        setError(err.message || 'Failed to create filter. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [filterName, onAddFilter, onClose]);

  const handleFilterNameChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length <= MAX_FILTER_NAME_LENGTH) {
      setFilterName(value);
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Add New Filter</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Filter Name</label>
            <input
              id="name"
              type="text"
              value={filterName}
              onChange={handleFilterNameChange}
              className="w-full px-3 py-2 border rounded"
              required
              maxLength={MAX_FILTER_NAME_LENGTH}
              placeholder="Enter filter name"
            />
            <p className="text-sm text-gray-500 mt-1">
              {filterName.length}/{MAX_FILTER_NAME_LENGTH} characters
            </p>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors disabled:opacity-50"
              disabled={isLoading || filterName.trim().length === 0}
            >
              {isLoading ? 'Adding...' : 'Add Filter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewFilterModal;