import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import { axiosInstance } from '../axiosConfig';

const BMRTDEECalculator = ({ clientId, onClose }) => {
  const [gender, setGender] = useState('male');
  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [activityLevel, setActivityLevel] = useState('sedentary');
  const [bmr, setBMR] = useState(null);
  const [tdee, setTDEE] = useState(null);

  const activityMultipliers = {
    sedentary: 1.2,
    light: 1.375,
    moderate: 1.55,
    active: 1.725,
    veryActive: 1.9
  };

  const calculateBMR = () => {
    const weightInKg = weight * 0.453592;
    const heightInCm = (Number(heightFeet) * 30.48) + (Number(heightInches) * 2.54);
    let result;
    if (gender === 'male') {
      result = 88.362 + (13.397 * weightInKg) + (4.799 * heightInCm) - (5.677 * age);
    } else {
      result = 447.593 + (9.247 * weightInKg) + (3.098 * heightInCm) - (4.330 * age);
    }
    return Math.round(result);
  };

  const calculateTDEE = (bmr) => {
    return Math.round(bmr * activityMultipliers[activityLevel]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const calculatedBMR = calculateBMR();
    const calculatedTDEE = calculateTDEE(calculatedBMR);
    setBMR(calculatedBMR);
    setTDEE(calculatedTDEE);

    const heightInCm = (Number(heightFeet) * 30.48) + (Number(heightInches) * 2.54);
    const date = new Date().toLocaleString();
    const noteText = `BMR and TDEE calculated.\n` +
                     `BMR: ${calculatedBMR} calories/day\n` +
                     `TDEE: ${calculatedTDEE} calories/day\n` +
                     `Formula used:\n` +
                     `BMR (${gender}): ${gender === 'male' ? '88.362 + (13.397 * weight in kg) + (4.799 * height in cm) - (5.677 * age)' : '447.593 + (9.247 * weight in kg) + (3.098 * height in cm) - (4.330 * age)'}\n` +
                     `TDEE: BMR * ${activityMultipliers[activityLevel]} (${activityLevel} activity level)\n` +
                     `Input values: Weight: ${weight} lbs, Height: ${heightFeet}'${heightInches}", Age: ${age}, Gender: ${gender}`;

    try {
      await axiosInstance.post(`/clients/${clientId}/notes/`, { text: noteText });
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  return (
    <Rnd
      default={{
        x: 0,
        y: 0,
        width: 320,
        height: 480,
      }}
      minWidth={300}
      minHeight={400}
      bounds="window"
    >
      <div className="p-6 bg-gray-100 h-full box-border shadow-lg rounded-lg relative">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-4 text-gray-800">BMR and TDEE Calculator</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Gender:
              <select 
                value={gender} 
                onChange={(e) => setGender(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </label>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Age:
              <input 
                type="number" 
                value={age} 
                onChange={(e) => setAge(e.target.value)} 
                required 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </label>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Weight (lbs):
              <input 
                type="number" 
                value={weight} 
                onChange={(e) => setWeight(e.target.value)} 
                required 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </label>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Height (feet):
                <input 
                  type="number" 
                  value={heightFeet} 
                  onChange={(e) => setHeightFeet(e.target.value)} 
                  required 
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Height (inches):
                <input 
                  type="number" 
                  value={heightInches} 
                  onChange={(e) => setHeightInches(e.target.value)} 
                  required 
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Activity Level:
              <select 
                value={activityLevel} 
                onChange={(e) => setActivityLevel(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="sedentary">Sedentary</option>
                <option value="light">Lightly Active</option>
                <option value="moderate">Moderately Active</option>
                <option value="active">Active</option>
                <option value="veryActive">Very Active</option>
              </select>
            </label>
          </div>
          <button 
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Calculate
          </button>
        </form>
        {bmr && tdee && (
          <div className="mt-6 p-4 bg-white rounded-md shadow">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Results:</h3>
            <p className="text-sm text-gray-600">Your BMR: <span className="font-bold">{bmr} calories/day</span></p>
            <p className="text-sm text-gray-600">Your TDEE: <span className="font-bold">{tdee} calories/day</span></p>
          </div>
        )}
      </div>
    </Rnd>
  );
};

export default BMRTDEECalculator;