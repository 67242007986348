import React, { useState, useRef, useEffect } from 'react';
import CustomFilterService from './services/CustomFilterService';
import { markAllCheckInsAsRead } from '../api';

const ClientHeader = ({ client, onToggleCheckIn, onToggleMessages, customFilters, onAssignFilter, onAddFilter }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const [assignedFilters, setAssignedFilters] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (client.filters) {
      setAssignedFilters(client.filters);
    } else {
      fetchAssignedFilters();
    }
  }, [client]);

  const handleMarkAllAsRead = async () => {
    setIsMarkingAsRead(true);
    setError(null);
    try {
      await markAllCheckInsAsRead(client.id);
    } catch (err) {
      console.error('Error marking check-ins as read:', err);
      setError('Failed to mark check-ins as read. Please try again.');
    } finally {
      setIsMarkingAsRead(false);
    }
  };

  const fetchAssignedFilters = async () => {
    setIsLoading(true);
    try {
      const filters = await CustomFilterService.getFiltersForClient(client.id);
      setAssignedFilters(filters.map(filter => filter.id));
    } catch (error) {
      console.error('Error fetching assigned filters:', error);
      setError('Failed to fetch assigned filters. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterAction = async (filterId) => {
    setIsLoading(true);
    setError(null);
    try {
      if (assignedFilters.includes(filterId)) {
        await CustomFilterService.removeFilterFromClient(filterId, client.id);
        setAssignedFilters(prevFilters => prevFilters.filter(id => id !== filterId));
        onAssignFilter(client.id, filterId, 'remove');
      } else {
        await CustomFilterService.assignFilterToClient(filterId, client.id);
        setAssignedFilters(prevFilters => [...prevFilters, filterId]);
        onAssignFilter(client.id, filterId, 'assign');
      }
    } catch (err) {
      console.error('Error updating filter:', err);
      setError('Failed to update filter. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>
        <img
          className="h-32 w-full object-cover lg:h-48"
          src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          alt=""
        />
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img
              className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
              src={`https://ui-avatars.com/api/?name=${client.first_name}+${client.last_name}&background=random`}
              alt=""
            />
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">
                {client.first_name} {client.last_name}
              </h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`https://power-data-3299.lightning.force.com/_ui/search/ui/UnifiedSearchResults?str=${encodeURIComponent(client.email)}`, '_blank', 'noopener,noreferrer');
                }}
                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Salesforce
              </button>
              <button
                onClick={handleMarkAllAsRead}
                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                disabled={isMarkingAsRead}
              >
                {isMarkingAsRead ? 'Marking...' : 'Mark All as Read'}
              </button>
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  disabled={isLoading}
                >
                  {isLoading ? 'Updating...' : 'Assign Filter'}
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <div className="py-1">
                      {error && <p className="text-red-500 px-4 py-2 text-sm">{error}</p>}
                      {customFilters.map((filter) => (
                        <button
                          key={filter.id}
                          onClick={() => handleFilterAction(filter.id)}
                          className="flex items-center justify-between w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          disabled={isLoading}
                        >
                          <span>{filter.name}</span>
                          {assignedFilters.includes(filter.id) && (
                            <span className="text-green-500 font-bold">✓</span>
                          )}
                        </button>
                      ))}
                      <button
                        onClick={() => {
                          onAddFilter();
                          setIsDropdownOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-blue-500 hover:bg-gray-100"
                        disabled={isLoading}
                      >
                        + Add New Filter
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">
            {client.first_name} {client.last_name}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ClientHeader;