import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import WorkoutSearchDropdown from './WorkoutSearchDropdown';
import {
  ChevronDown,
  ChevronUp,
  Clock,
  Dumbbell,
  Gauge,
  Activity,
  Image as ImageIcon,
} from 'lucide-react';

const WorkoutBuilder = ({ onSave, onCancel, clientId, template }) => {
  const [workoutPlan, setWorkoutPlan] = useState({
    title: '',
    description: '',
    sessions: [],
  });

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [error, setError] = useState(null);
  const [expandedSessions, setExpandedSessions] = useState({});

  useEffect(() => {
    if (template) {
      setWorkoutPlan({
        title: template.title || '',
        description: template.description || '',
        sessions:
          template.sessions?.map((session) => ({
            ...session,
            movements:
              session.movements?.map((movement) => ({
                ...movement,
                prescribed_sets: movement.prescribed_sets || 0,
                prescribed_reps: movement.prescribed_reps || '',
                prescribed_rest: movement.prescribed_rest || '',
                notes: movement.notes || '',
              })) || [],
          })) || [],
      });
    }
  }, [template]);

  const addSession = () => {
    setWorkoutPlan((prev) => ({
      ...prev,
      sessions: [
        ...prev.sessions,
        {
          day: prev.sessions.length + 1,
          coaches_intro_note: '',
          approx_time: '',
          difficulty_level: '',
          tempo: '',
          best_practice: '',
          image_url: '',
          movements: [],
        },
      ],
    }));
  };

  const addMovement = (sessionIndex) => {
    setWorkoutPlan((prev) => ({
      ...prev,
      sessions: prev.sessions.map((session, index) =>
        index === sessionIndex
          ? {
              ...session,
              movements: [
                ...session.movements,
                {
                  title: '',
                  prescribed_sets: 0,
                  prescribed_reps: '',
                  prescribed_rest: '',
                  notes: '',
                },
              ],
            }
          : session,
      ),
    }));
  };

  const handleWorkoutPlanChange = (e) => {
    const { name, value } = e.target;
    setWorkoutPlan((prev) => ({ ...prev, [name]: value }));
  };

  const handleSessionChange = (sessionIndex, e) => {
    const { name, value } = e.target;
    setWorkoutPlan((prev) => ({
      ...prev,
      sessions: prev.sessions.map((session, index) =>
        index === sessionIndex ? { ...session, [name]: value } : session,
      ),
    }));
  };

  const handleMovementChange = (sessionIndex, movementIndex, e) => {
    const { name, value } = e.target;
    setWorkoutPlan((prev) => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              movements: session.movements.map((movement, mIndex) =>
                mIndex === movementIndex
                  ? { ...movement, [name]: value }
                  : movement,
              ),
            }
          : session,
      ),
    }));
  };

  const handleMovementTitleClick = (sessionIndex, movementIndex) => {
    setActiveDropdown({ sessionIndex, movementIndex });
  };

  const handleWorkoutSelect = (
    sessionIndex,
    movementIndex,
    selectedWorkout,
  ) => {
    handleMovementChange(sessionIndex, movementIndex, {
      target: { name: 'title', value: selectedWorkout.title },
    });
    if (selectedWorkout.video_url) {
      handleMovementChange(sessionIndex, movementIndex, {
        target: { name: 'video_url', value: selectedWorkout.video_url },
      });
    }
    setActiveDropdown(null);
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  const handleSaveNewPlan = async () => {
    try {
      // Log the complete workoutPlan for debugging
      console.log(
        'Complete WorkoutPlan:',
        JSON.stringify(workoutPlan, null, 2),
      );

      // Don't modify or restructure the data, just pass it directly
      onSave({
        title: workoutPlan.title,
        description: workoutPlan.description,
        sessions: workoutPlan.sessions,
      });
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to create new workout plan. Please try again.');
    }
  };

  const toggleSession = (sessionIndex) => {
    setExpandedSessions((prev) => ({
      ...prev,
      [sessionIndex]: !prev[sessionIndex],
    }));
  };

  return (
    <div className="bg-white rounded-xl shadow-lg max-w-4xl mx-auto">
      <div className="p-6 border-b border-gray-200 bg-gray-50/80">
        <h2 className="text-2xl font-bold text-gray-800">
          {template
            ? 'Create Workout Plan from Template'
            : 'Create Workout Plan'}
        </h2>
      </div>

      {error && (
        <div className="mx-6 mt-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
          {error}
        </div>
      )}

      <div className="p-6 space-y-6">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={workoutPlan.title}
              onChange={handleWorkoutPlanChange}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
              placeholder="Enter workout plan title..."
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={workoutPlan.description}
              onChange={handleWorkoutPlanChange}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow resize-none"
              rows="3"
              placeholder="Describe the workout plan..."
            />
          </div>
        </div>

        <div className="space-y-4">
          {workoutPlan.sessions.map((session, sessionIndex) => (
            <div
              key={sessionIndex}
              className="border border-gray-200 rounded-lg bg-white shadow-sm"
            >
              <div className="p-4 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-gray-800">
                    Session {session.day}
                  </h3>
                  <button
                    onClick={() => toggleSession(sessionIndex)}
                    className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    {expandedSessions[sessionIndex] ? (
                      <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>

              {expandedSessions[sessionIndex] && (
                <div className="p-4 space-y-4">
                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">
                      Coach's Intro Note
                    </label>
                    <textarea
                      name="coaches_intro_note"
                      value={session.coaches_intro_note}
                      onChange={(e) => handleSessionChange(sessionIndex, e)}
                      className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow resize-none"
                      rows="2"
                      placeholder="Add coach's introduction..."
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                      <Clock className="w-4 h-4 text-gray-500" />
                      <div className="flex-1">
                        <label className="block text-sm font-semibold text-gray-700 mb-1">
                          Approx. Time
                        </label>
                        <input
                          type="text"
                          name="approx_time"
                          value={session.approx_time}
                          onChange={(e) => handleSessionChange(sessionIndex, e)}
                          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                          placeholder="e.g., 45 minutes"
                        />
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <Gauge className="w-4 h-4 text-gray-500" />
                      <div className="flex-1">
                        <label className="block text-sm font-semibold text-gray-700 mb-1">
                          Difficulty Level
                        </label>
                        <select
                          name="difficulty_level"
                          value={session.difficulty_level}
                          onChange={(e) => handleSessionChange(sessionIndex, e)}
                          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                        >
                          <option value="">Select difficulty</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                      <Activity className="w-4 h-4 text-gray-500" />
                      <div className="flex-1">
                        <label className="block text-sm font-semibold text-gray-700 mb-1">
                          Tempo
                        </label>
                        <input
                          type="text"
                          name="tempo"
                          value={session.tempo}
                          onChange={(e) => handleSessionChange(sessionIndex, e)}
                          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                          placeholder="e.g., Slow and controlled"
                        />
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <ImageIcon className="w-4 h-4 text-gray-500" />
                      <div className="flex-1">
                        <label className="block text-sm font-semibold text-gray-700 mb-1">
                          Image URL
                        </label>
                        <input
                          type="text"
                          name="image_url"
                          value={session.image_url}
                          onChange={(e) => handleSessionChange(sessionIndex, e)}
                          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                          placeholder="https://..."
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">
                      Best Practice
                    </label>
                    <textarea
                      name="best_practice"
                      value={session.best_practice}
                      onChange={(e) => handleSessionChange(sessionIndex, e)}
                      className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow resize-none"
                      rows="2"
                      placeholder="Add best practice notes..."
                    />
                  </div>

                  <div className="mt-6">
                    <div className="flex items-center justify-between mb-4">
                      <h4 className="text-md font-semibold text-gray-800">
                        Movements
                      </h4>
                      <button
                        onClick={() => addMovement(sessionIndex)}
                        className="flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition-colors"
                      >
                        <PlusIcon className="w-4 h-4 mr-1" /> Add Movement
                      </button>
                    </div>

                    <div className="space-y-4">
                      {session.movements.map((movement, movementIndex) => (
                        <div
                          key={movementIndex}
                          className="p-4 bg-gray-50 border border-gray-200 rounded-lg"
                        >
                          <div className="relative mb-4">
                            <label className="block text-sm font-semibold text-gray-700 mb-1">
                              Movement
                            </label>
                            <div className="flex items-center">
                              <Dumbbell className="w-4 h-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                              <input
                                type="text"
                                name="title"
                                value={movement.title}
                                onClick={() =>
                                  handleMovementTitleClick(
                                    sessionIndex,
                                    movementIndex,
                                  )
                                }
                                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg cursor-pointer focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                                readOnly
                                placeholder="Select a movement..."
                              />
                            </div>
                            {activeDropdown &&
                              activeDropdown.sessionIndex === sessionIndex &&
                              activeDropdown.movementIndex ===
                                movementIndex && (
                                <WorkoutSearchDropdown
                                  onSelect={(workout) =>
                                    handleWorkoutSelect(
                                      sessionIndex,
                                      movementIndex,
                                      workout,
                                    )
                                  }
                                  onClose={closeDropdown}
                                />
                              )}
                          </div>

                          <div className="grid grid-cols-3 gap-4 mb-4">
                            <div>
                              <label className="block text-sm font-semibold text-gray-700 mb-1">
                                Sets
                              </label>
                              <input
                                type="number"
                                name="prescribed_sets"
                                value={movement.prescribed_sets}
                                onChange={(e) =>
                                  handleMovementChange(
                                    sessionIndex,
                                    movementIndex,
                                    e,
                                  )
                                }
                                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                                placeholder="0"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-semibold text-gray-700 mb-1">
                                Reps
                              </label>
                              <input
                                type="text"
                                name="prescribed_reps"
                                value={movement.prescribed_reps}
                                onChange={(e) =>
                                  handleMovementChange(
                                    sessionIndex,
                                    movementIndex,
                                    e,
                                  )
                                }
                                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                                placeholder="e.g., 10-12"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-semibold text-gray-700 mb-1">
                                Rest
                              </label>
                              <input
                                type="text"
                                name="prescribed_rest"
                                value={movement.prescribed_rest}
                                onChange={(e) =>
                                  handleMovementChange(
                                    sessionIndex,
                                    movementIndex,
                                    e,
                                  )
                                }
                                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                                placeholder="e.g., 60s"
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-semibold text-gray-700 mb-1">
                              Notes
                            </label>
                            <textarea
                              name="notes"
                              value={movement.notes}
                              onChange={(e) =>
                                handleMovementChange(
                                  sessionIndex,
                                  movementIndex,
                                  e,
                                )
                              }
                              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow resize-none"
                              rows="2"
                              placeholder="Add movement notes..."
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <button
          onClick={addSession}
          className="flex items-center px-4 py-2 text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition-colors"
        >
          <PlusIcon className="w-5 h-5 mr-2" /> Add Session
        </button>
        <div className="flex justify-end items-center gap-3 mt-8 pt-6 border-t border-gray-200">
          <button
            onClick={onCancel}
            className="px-6 py-2 border border-gray-200 text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveNewPlan}
            className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
          >
            Create Workout Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkoutBuilder;
