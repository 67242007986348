import React, { useState } from 'react';
import AddNewFilterModal from './AddNewFilterModal';
import { ChevronDown, ChevronRight, UserPlus, Key } from 'lucide-react';
import { addClient, sendPasswordResetEmail } from '../api';

const Sidebar = ({
  clients,
  onClientSelect,
  onFilterChange,
  currentFilter,
  customFilters,
  onAddFilter,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState(false);
  const [isAccountManagementOpen, setIsAccountManagementOpen] = useState(false);
  const [coachUsername, setCoachUsername] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');
  const [newClientFirstName, setNewClientFirstName] = useState('');
  const [newClientLastName, setNewClientLastName] = useState('');
  const [newClientPassword, setNewClientPassword] = useState('');
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');

  const filteredClients = clients.filter((client) =>
    `${client.first_name || ''} ${client.last_name || ''}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const groupClientsByFirstLetter = () => {
    return filteredClients.reduce((acc, client) => {
      const name = (client.last_name || client.first_name || '?').trim();
      const firstLetter = name[0] ? name[0].toUpperCase() : '?';
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(client);
      return acc;
    }, {});
  };

  const filters = [
    { key: 'all', label: 'All Clients' },
    { key: 'workout_plans', label: 'Needs Workout Plan' },
    { key: 'starting_soon', label: 'Starting Soon' },
    { key: 'meal_plans', label: 'Needs Meal Plan' },
    { key: 'unread_messages', label: 'Unread Messages' },
    { key: 'unread_checkins', label: 'Unread Check-ins' },
    ...customFilters.map((filter) => ({
      key: `custom_${filter.id}`,
      label: filter.name,
      id: filter.id,
    })),
    { key: 'add_new_filter', label: '+ Add New Filter' },
  ];

  const handleFilterChange = (value) => {
    if (value === 'add_new_filter') {
      setIsAddFilterModalOpen(true);
    } else {
      onFilterChange(value);
    }
  };

  const handleAddFilter = (newFilter) => {
    onAddFilter(newFilter);
    setIsAddFilterModalOpen(false);
  };

  const handleAddClient = async () => {
    try {
      await addClient(
        coachUsername,
        newClientEmail,
        newClientFirstName,
        newClientLastName,
        newClientPassword,
      );
      // Reset form fields
      setCoachUsername('');
      setNewClientEmail('');
      setNewClientFirstName('');
      setNewClientLastName('');
      setNewClientPassword('');
      // You might want to refresh the client list here
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(resetPasswordEmail);
      setResetPasswordEmail('');
    } catch (error) {
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <div className="h-full flex flex-col">
      {/* Header Section */}
      <div className="p-6">
        <h2 className="text-2xl font-semibold text-gray-900">Directory</h2>
        <p className="mt-1 text-sm text-gray-600">
          Search directory of {clients.length} clients
        </p>
        <div className="mt-4 relative">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-md"
          />
          <svg
            className="absolute left-3 top-3 h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <div className="mt-4">
          <select
            value={currentFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          >
            {filters.map((filter) => (
              <option key={filter.key} value={filter.key}>
                {filter.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Client List Section */}
      <nav className="flex-1 overflow-y-auto px-6">
        {Object.entries(groupClientsByFirstLetter()).map(
          ([letter, letterClients]) => (
            <div key={letter} className="mb-4">
              <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider">
                {letter}
              </h3>
              <ul className="mt-2">
                {letterClients.map((client) => (
                  <li key={client.id}>
                    <button
                      onClick={() => onClientSelect(client.id)}
                      className="w-full flex items-center py-3 hover:bg-gray-100"
                    >
                      <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center mr-3">
                        {client.profile_image ? (
                          <img
                            src={client.profile_image}
                            alt={`${client.first_name || ''} ${client.last_name || ''}`}
                            className="h-10 w-10 rounded-full object-cover"
                          />
                        ) : (
                          <span className="text-xl font-bold text-gray-600">
                            {(client.last_name && client.last_name[0]) ||
                              (client.first_name && client.first_name[0]) ||
                              '?'}
                          </span>
                        )}
                      </div>
                      <div className="text-left flex-grow flex items-center justify-between">
                        <div>
                          <p className="text-sm font-medium text-gray-900">
                            {client.last_name && client.first_name
                              ? `${client.last_name}, ${client.first_name}`
                              : client.last_name ||
                                client.first_name ||
                                'Unnamed Client'}
                          </p>
                          <p className="text-xs text-gray-500">
                            {client.product || 'No product'}
                          </p>
                        </div>
                        {client.unread_count > 0 &&
                          currentFilter === 'unread_messages' && (
                            <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full">
                              {client.unread_count}
                            </span>
                          )}
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ),
        )}
      </nav>

      {/* Account Management Section */}
      <div className="bg-white border-t border-gray-200 mt-auto">
        <button
          onClick={() => setIsAccountManagementOpen(!isAccountManagementOpen)}
          className="w-full flex items-center justify-between p-4 hover:bg-gray-100 focus:outline-none"
        >
          <span className="font-medium text-gray-700">Account Management</span>
          {isAccountManagementOpen ? (
            <ChevronDown size={20} />
          ) : (
            <ChevronRight size={20} />
          )}
        </button>
        {isAccountManagementOpen && (
          <div className="p-4 max-h-96 overflow-y-auto">
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Add New Client</h4>
              <input
                type="text"
                placeholder="Coach Username"
                value={coachUsername}
                onChange={(e) => setCoachUsername(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="email"
                placeholder="Client Email"
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                placeholder="Client First Name"
                value={newClientFirstName}
                onChange={(e) => setNewClientFirstName(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                placeholder="Client Last Name"
                value={newClientLastName}
                onChange={(e) => setNewClientLastName(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="password"
                placeholder="Client Password"
                value={newClientPassword}
                onChange={(e) => setNewClientPassword(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <button
                onClick={handleAddClient}
                className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
              >
                <UserPlus size={20} className="mr-2" />
                Add Client
              </button>
            </div>
            <div>
              <h4 className="font-semibold mb-2">Password Reset</h4>
              <input
                type="email"
                placeholder="Client Username"
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                className="w-full p-2 mb-2 border rounded"
              />
              <button
                onClick={handlePasswordReset}
                className="w-full p-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center"
              >
                <Key size={20} className="mr-2" />
                Send Reset Email
              </button>
            </div>
          </div>
        )}
      </div>

      <AddNewFilterModal
        isOpen={isAddFilterModalOpen}
        onClose={() => setIsAddFilterModalOpen(false)}
        onAddFilter={handleAddFilter}
      />
    </div>
  );
};

export default Sidebar;
