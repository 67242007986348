import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../axiosConfig';
import { Rnd } from 'react-rnd';

const WorkoutPlanLibrary = ({ clientId, onClose, onAssign, onCreate, mode }) => {
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchAllWorkoutPlans();
  }, []);

  useEffect(() => {
    const filtered = workoutPlans
      .filter(plan => plan.title && plan.title.trim() !== '')
      .filter((plan, index, self) => 
        index === self.findIndex((t) => t.title.toLowerCase() === plan.title.toLowerCase())
      )
      .filter(plan =>
        plan.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        plan.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setFilteredPlans(filtered);
  }, [searchTerm, workoutPlans]);

  const fetchAllWorkoutPlans = async () => {
    try {
      const response = await axiosInstance.get('/cd-workout/library/');
      setWorkoutPlans(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching all workout plans:', err);
      setError('Failed to fetch workout plans. Please try again.');
      setLoading(false);
    }
  };

  const assignPlanToClient = async (planId) => {
    try {
      await axiosInstance.post(`/cd-workout/${planId}/assign_to_client/`, {
        client_id: clientId
      });
      onAssign();
      onClose();
    } catch (err) {
      console.error('Error assigning workout plan:', err);
      setError('Failed to assign workout plan. Please try again.');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePlanAction = (plan) => {
    if (mode === 'assign') {
      assignPlanToClient(plan.id);
    } else if (mode === 'create') {
      onCreate(plan);
    }
  };

  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 600,
        height: 800,
      }}
      minWidth={400}
      minHeight={400}
      bounds="window"
      className="fixed z-50 bg-white shadow-lg rounded-lg overflow-hidden"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b cursor-move drag-handle">
          <h2 className="text-xl font-bold">Workout Plan Library</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            Close
          </button>
        </div>
        <div className="p-4 border-b">
          <input
            type="text"
            placeholder="Search workout plans..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {loading && <p>Loading workout plans...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {!loading && !error && (
            <ul className="space-y-4">
              {filteredPlans.map(plan => (
                <li key={plan.id} className="border rounded-lg p-4">
                  <h3 className="text-lg font-semibold">{plan.title}</h3>
                  <p className="text-gray-600 mt-2">{plan.description}</p>
                  <button
                    onClick={() => handlePlanAction(plan)}
                    className="mt-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    {mode === 'assign' ? 'Assign to Client' : 'Use as Template'}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Rnd>
  );
};

export default WorkoutPlanLibrary;