import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { parse, format, subWeeks, parseISO } from 'date-fns';
import { fetchWeightData } from '../api';

const ClientProgressGraphs = ({ notes, clientId }) => {
  const [calorieData, setCalorieData] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [timeRange, setTimeRange] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const parsedData = parseNotes(notes);
    setCalorieData(parsedData);

    const fetchWeight = async () => {
      setIsLoading(true);
      try {
        const data = await fetchWeightData(clientId);
        const parsedWeightData = data.map(item => ({
          ...item,
          date: parseISO(item.date)
        }));
        setWeightData(parsedWeightData);
      } catch (err) {
        setError('Failed to fetch weight data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWeight();
  }, [notes, clientId]);

  const parseNotes = (notes) => {
    return notes
      .map(note => {
        const match = note.text.match(/Macros updated on (.*?)\nCalories: (\d+) -> (\d+)/);
        if (match) {
          return {
            date: parse(match[1], 'M/d/yyyy, h:mm:ss a', new Date()),
            calories: parseInt(match[3], 10)
          };
        }
        return null;
      })
      .filter(Boolean)
      .sort((a, b) => a.date - b.date);
  };

  const getFilteredData = (data) => {
    if (timeRange === 'last4weeks') {
      const fourWeeksAgo = subWeeks(new Date(), 4);
      return data.filter(item => item.date >= fourWeeksAgo);
    }
    return data;
  };

  const renderLineChart = (data, dataKey, label, color) => {
    if (data.length === 0) {
      return <p className="text-gray-500 italic">No {label.toLowerCase()} data available.</p>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} className="bg-white rounded-lg shadow-md p-4">
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis 
            dataKey="date" 
            tickFormatter={(date) => format(date, 'MM/dd/yyyy')}
            stroke="#4a5568"
          />
          <YAxis stroke="#4a5568" />
          <Tooltip 
            labelFormatter={(date) => format(new Date(date), 'MM/dd/yyyy')}
            formatter={(value) => [`${value} ${dataKey === 'calories' ? 'calories' : 'lbs'}`, label]}
            contentStyle={{ backgroundColor: '#f7fafc', border: '1px solid #e2e8f0' }}
          />
          <Legend />
          <Line type="monotone" dataKey={dataKey} stroke={color} activeDot={{ r: 8 }} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  if (isLoading) {
    return <div className="text-center text-gray-600">Loading progress data...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  const filteredCalorieData = getFilteredData(calorieData);
  const filteredWeightData = getFilteredData(weightData);

  return (
    <div className="mt-8 bg-gray-50 p-6 rounded-xl shadow-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Client Progress Graphs</h2>
      <div className="mb-6">
        <label htmlFor="timeRange" className="mr-2 text-gray-700 font-medium">Time Range:</label>
        <select
          id="timeRange"
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="border rounded-md px-3 py-2 bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Time</option>
          <option value="last4weeks">Last 4 Weeks</option>
        </select>
      </div>
      <div className="mb-10">
        <h3 className="text-xl font-medium text-gray-700 mb-4">Weight Changes Over Time</h3>
        {renderLineChart(filteredWeightData, 'weight', 'Weight', '#10B981')}
      </div>
      <div className="mb-10">
        <h3 className="text-xl font-medium text-gray-700 mb-4">Calorie Changes Over Time</h3>
        {renderLineChart(filteredCalorieData, 'calories', 'Calories', '#3B82F6')}
      </div>
    </div>
  );
};

export default ClientProgressGraphs;