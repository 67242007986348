import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { axiosInstance } from '../axiosConfig';
import ReactLoading from 'react-loading';
import Sidebar from './Sidebar';
import ClientProfile from './ClientProfile';
import MealPlan from './MealPlan';
import WorkoutPlanBuilder from './WorkoutPlanBuilder';
import Notes from './Notes';
import Footer from './Footer';
import CheckInPanel from './CheckInPanel';
import LastTwoCheckInsPanel from './LastTwoCheckInsPanel';
import MessagesPanel from './MessagesPanel';
import TabSelector from './TabSelector';
import ClientHeader from './ClientHeader';
import {
  toggleCheckInPanel,
  toggleMessagesPanel,
  toggleLastTwoCheckInPanel,
} from '../redux/slices/panelSlice';
import CustomFilterService from './services/CustomFilterService';
import ClientProgressGraphs from './ClientProgressGraphs';

const ClientDirectory = () => {
  const dispatch = useDispatch();
  const { checkInPanelOpen, messagesPanelOpen, lastTwoCheckInPanelOpen } =
    useSelector((state) => state.panels);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filter, setFilter] = useState('all');
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState('Profile');
  const [customFilters, setCustomFilters] = useState([]);
  const [clientNotes, setClientNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleLastTwoCheckInPanel = () =>
    dispatch(toggleLastTwoCheckInPanel());

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([fetchClients(), fetchCustomFilters()]);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to fetch initial data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotesUpdate = (updatedNotes) => {
    setClientNotes(updatedNotes);
  };

  const fetchCustomFilters = async () => {
    try {
      const filters = await CustomFilterService.getAllFilters();
      setCustomFilters(filters);
    } catch (error) {
      console.error('Error fetching custom filters:', error);
    }
  };

  const handleAddFilter = async (newFilter) => {
    setIsLoading(true);
    try {
      const createdFilter = await CustomFilterService.createFilter(newFilter);
      setCustomFilters((prevFilters) => {
        if (prevFilters.some((filter) => filter.id === createdFilter.id)) {
          return prevFilters;
        }
        return [...prevFilters, createdFilter];
      });
    } catch (error) {
      console.error('Error creating new filter:', error);
      setError('Failed to create new filter. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        '/coach-dashboard/active-clients/',
      );
      if (response.data && typeof response.data.count === 'number') {
        console.log(`Found ${response.data.count} active clients.`);
        setClients(response.data.clients || []);
      } else {
        throw new Error('Unexpected response format');
      }
      setError(null);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError(
        error.response?.data?.detail ||
          error.message ||
          'Failed to fetch clients. Please try again.',
      );
      setClients([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = async (newFilter) => {
    setFilter(newFilter);
    setIsLoading(true);
    try {
      let endpoint;
      switch (newFilter) {
        case 'all':
          endpoint = '/coach-dashboard/active-clients/';
          break;
        case 'workout_plans':
          endpoint = '/coach-dashboard/clients-needing-workout-plans/';
          break;
        case 'starting_soon':
          endpoint = '/coach-dashboard/clients-starting-soon/';
          break;
        case 'meal_plans':
          endpoint = '/coach-dashboard/clients-needing-meal-plans/';
          break;
        case 'unread_messages':
          endpoint = '/coach-dashboard/unread-messages/';
          break;
        case 'unread_checkins':
          endpoint = '/coach-dashboard/unread-checkins/';
          break;
        default:
          if (newFilter.startsWith('custom_')) {
            const filterId = newFilter.split('_')[1];
            endpoint = `/coach-dashboard/custom-filters/${filterId}/`;
          } else {
            throw new Error('Invalid filter type');
          }
      }

      const response = await axiosInstance.get(endpoint);

      if (newFilter === 'unread_messages') {
        if (response.data && Array.isArray(response.data.messages)) {
          const clientsWithUnread = response.data.messages.reduce(
            (acc, message) => {
              if (!acc[message.client_id]) {
                acc[message.client_id] = {
                  id: message.client_id,
                  first_name: message.first_name,
                  last_name: message.last_name,
                  unread_count: 1,
                };
              } else {
                acc[message.client_id].unread_count++;
              }
              return acc;
            },
            {},
          );
          setClients(Object.values(clientsWithUnread));
          console.log(
            `Found ${Object.keys(clientsWithUnread).length} clients with unread messages.`,
          );
        } else {
          throw new Error('Unexpected response format for unread messages');
        }
      } else if (newFilter === 'unread_checkins') {
        if (response.data && Array.isArray(response.data.checkins)) {
          const clientsWithUnread = response.data.checkins.reduce(
            (acc, checkin) => {
              const clientKey = checkin.client_id;
              if (!acc[clientKey]) {
                acc[clientKey] = {
                  id: checkin.client_id,
                  first_name: checkin.first_name,
                  last_name: checkin.last_name,
                  unread_count: 1,
                };
              } else {
                acc[clientKey].unread_count++;
              }
              return acc;
            },
            {},
          );

          setClients(Object.values(clientsWithUnread));
          console.log(
            `Found ${Object.values(clientsWithUnread).length} clients with unread check-ins.`,
          );
        } else {
          throw new Error('Unexpected response format for unread check-ins');
        }
      } else if (newFilter.startsWith('custom_')) {
        // Handle custom filter response
        if (response.data && Array.isArray(response.data.clients)) {
          setClients(response.data.clients);
          console.log(
            `Found ${response.data.count} clients for custom filter: ${response.data.filter_name}`,
          );
        } else {
          throw new Error('Unexpected response format for custom filter');
        }
      } else {
        // Handle 'all' and other filters
        if (response.data && Array.isArray(response.data.clients)) {
          setClients(response.data.clients);
          console.log(
            `Found ${response.data.clients.length} clients for filter: ${newFilter}`,
          );
        } else {
          throw new Error('Unexpected response format');
        }
      }
      setError(null);
    } catch (error) {
      console.error('Error fetching filtered clients:', error);
      setError(
        error.response?.data?.detail ||
          error.message ||
          'Failed to fetch filtered clients. Please try again.',
      );
      setClients([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientProfile = async (clientId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/clients/${clientId}/`);
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }
      setSelectedClient(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching client profile:', error);
      setError(
        error.response?.data?.detail ||
          error.message ||
          'Failed to fetch client profile. Please try again.',
      );
      setSelectedClient(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleCheckInPanel = () => dispatch(toggleCheckInPanel());
  const handleToggleMessagesPanel = () => dispatch(toggleMessagesPanel());

  const handleAssignFilter = async (clientId, filterKey, action) => {
    setIsLoading(true);
    try {
      if (action === 'assign') {
        await CustomFilterService.assignFilterToClient(filterKey, clientId);
      } else {
        await CustomFilterService.removeFilterFromClient(filterKey, clientId);
      }
      setSelectedClient((prevClient) => {
        const updatedFilters =
          action === 'assign'
            ? [...(prevClient.filters || []), filterKey]
            : (prevClient.filters || []).filter((f) => f !== filterKey);
        return { ...prevClient, filters: updatedFilters };
      });
    } catch (error) {
      console.error(
        `Error ${action === 'assign' ? 'assigning' : 'removing'} filter:`,
        error,
      );
      setError(
        `Failed to ${action === 'assign' ? 'assign' : 'remove'} filter. Please try again.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex overflow-hidden">
        {/* Sidebar */}
        <div className="w-80 flex-shrink-0 flex flex-col border-r border-gray-200 bg-white overflow-y-auto">
          <Sidebar
            clients={clients}
            onClientSelect={fetchClientProfile}
            onFilterChange={handleFilterChange}
            currentFilter={filter}
            customFilters={customFilters}
            onAssignFilter={handleAssignFilter}
            onAddFilter={handleAddFilter}
            isLoading={isLoading}
          />
        </div>
        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {error && <div className="text-red-500 p-4">{error}</div>}
          <main className="flex-1 overflow-y-auto">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <ReactLoading
                  type="balls"
                  color="#50c878"
                  height={100}
                  width={100}
                />
              </div>
            ) : selectedClient ? (
              <div className="h-full flex flex-col">
                <ClientHeader
                  client={selectedClient}
                  onToggleCheckIn={handleToggleCheckInPanel}
                  onToggleMessages={handleToggleMessagesPanel}
                  customFilters={customFilters}
                  onAssignFilter={handleAssignFilter}
                  onAddFilter={handleAddFilter}
                />
                <div className="px-0 py-4">
                  <TabSelector
                    tabs={[
                      { name: 'Profile', key: 'Profile' },
                      { name: 'Progress Graphs', key: 'Progress Graphs' },
                      { name: 'Meal Plan', key: 'Meal Plan' },
                      { name: 'Workout Plan', key: 'Workout Plan' },
                    ]}
                    currentTab={currentTab}
                    onTabChange={setCurrentTab}
                  />
                </div>
                <div className="flex-1 overflow-y-auto px-20 py-6">
                  <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                      <div className="md:col-span-2">
                        {currentTab === 'Profile' && (
                          <ClientProfile client={selectedClient} />
                        )}
                        {currentTab === 'Progress Graphs' && (
                          <ClientProgressGraphs
                            notes={clientNotes}
                            clientId={selectedClient.id}
                          />
                        )}
                        {currentTab === 'Meal Plan' && (
                          <MealPlan clientId={selectedClient.id} />
                        )}
                        {currentTab === 'Workout Plan' && (
                          <WorkoutPlanBuilder clientId={selectedClient.id} />
                        )}
                      </div>
                      <div className="md:col-span-1">
                        <Notes
                          clientId={selectedClient.id}
                          onNotesUpdate={handleNotesUpdate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center mt-10">
                Select a client to view details
              </p>
            )}
          </main>
        </div>
      </div>
      {/* Footer */}
      <div className="flex-shrink-0">
        <Footer />
      </div>
      {/* Panels */}
      <CheckInPanel
        clientId={selectedClient ? selectedClient.id : null}
        isOpen={checkInPanelOpen}
        onToggle={handleToggleCheckInPanel}
      />
      <MessagesPanel
        clientId={selectedClient ? selectedClient.id : null}
        clientName={
          selectedClient
            ? `${selectedClient.first_name} ${selectedClient.last_name}`
            : ''
        }
        isOpen={messagesPanelOpen}
        onToggle={handleToggleMessagesPanel}
      />
      <LastTwoCheckInsPanel
        clientId={selectedClient ? selectedClient.id : null}
        isOpen={lastTwoCheckInPanelOpen}
        onToggle={handleToggleLastTwoCheckInPanel}
      />
    </div>
  );
};

export default ClientDirectory;
