import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../axiosConfig';

const Notes = ({ clientId, onNotesUpdate }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNotes();
  }, [clientId]);

  const fetchNotes = async () => {
    try {
      const response = await axiosInstance.get(`/clients/${clientId}/notes/`);
      setNotes(response.data);
      onNotesUpdate(response.data); // Pass notes data to parent
      setError(null);
    } catch (error) {
      console.error('Error fetching notes:', error);
      if (error.response && error.response.status === 404) {
        setError('No notes found for this client');
      } else {
        setError('Failed to fetch notes. Please try again.');
      }
      setNotes([]);
      onNotesUpdate([]); // Pass empty array to parent
    }
  };

  const addNote = async () => {
    if (newNote.trim()) {
      try {
        const response = await axiosInstance.post(`/clients/${clientId}/notes/`, { text: newNote });
        const updatedNotes = [response.data, ...notes];
        setNotes(updatedNotes);
        onNotesUpdate(updatedNotes); // Pass updated notes to parent
        setNewNote('');
        setError(null);
      } catch (error) {
        console.error('Error adding note:', error);
        setError('Failed to add note. Please try again.');
      }
    }
  };

  const deleteNote = async (noteId) => {
    try {
      await axiosInstance.delete(`/clients/${clientId}/notes/${noteId}/`);
      const updatedNotes = notes.filter(note => note.id !== noteId);
      setNotes(updatedNotes);
      onNotesUpdate(updatedNotes); // Pass updated notes to parent
      setError(null);
    } catch (error) {
      console.error('Error deleting note:', error);
      setError('Failed to delete note. Please try again.');
    }
  };

  return (
    <div>
      <h2 className="text-lg font-medium text-gray-900">Notes</h2>
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {notes.length > 0 ? (
        <ul className="mt-4 space-y-4">
          {notes.map((note) => (
            <li key={note.id} className="bg-white px-4 py-3 shadow rounded-lg flex justify-between items-start">
              <div>
                <p className="text-sm text-gray-800">{note.text}</p>
                <p className="text-xs text-gray-500 mt-1">
                  {new Date(note.created_at).toLocaleDateString()} {new Date(note.created_at).toLocaleTimeString()}
                </p>
              </div>
              <button
                onClick={() => deleteNote(note.id)}
                className="text-red-500 hover:text-red-700 focus:outline-none"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      ) : !error && (
        <p className="mt-4 text-gray-500">No notes available.</p>
      )}
      <div className="mt-4">
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          className="w-full px-3 py-2 text-sm text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
          rows="3"
          placeholder="Add a note..."
        ></textarea>
        <button
          onClick={addNote}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Add Note
        </button>
      </div>
    </div>
  );
};

export default Notes;