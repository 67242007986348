import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../axiosConfig';
import MacroInput from './MacroInput';
import { Tooltip } from 'react-tooltip';
import BMRTDEECalculator from './BMRTDEECalculator'; // Add this import
import { Info, Calculator, Save } from 'lucide-react';

const MealPlan = ({ clientId }) => {
  const [showBMRTDEE, setShowBMRTDEE] = useState(false);

  const toggleBMRTDEE = () => {
    setShowBMRTDEE(!showBMRTDEE);
  };

  const [macros, setMacros] = useState({
    calories: 0,
    carbs: 0,
    protein: 0,
    fat: 0,
    carbs_percentage: 0,
    protein_percentage: 0,
    fat_percentage: 0,
    number_of_meals: 0,
  });
  const [lockedMacros, setLockedMacros] = useState({
    protein: true, // Start locked
    carbs: true, // Start locked
    fat: true, // Start locked
    number_of_meals: false, // Start unlocked
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastChangedField, setLastChangedField] = useState(null);

  useEffect(() => {
    fetchClientMacros();
  }, [clientId]);

  const fetchClientMacros = async () => {
    try {
      const response = await axiosInstance.get(`/clients/macros/${clientId}/`);
      console.log('API response:', response.data);

      // Set default values and percentages when API returns null
      const initialMacros = {
        calories: response.data.calories || 0,
        protein: response.data.protein || 0,
        carbs: response.data.carbs || 0,
        fat: response.data.fat || 0,
        number_of_meals: response.data.number_of_meals || 0,
        protein_percentage:
          response.data.protein || response.data.protein_percentage
            ? response.data.protein_percentage || 0
            : 30,
        carbs_percentage:
          response.data.carbs || response.data.carbs_percentage
            ? response.data.carbs_percentage || 0
            : 50,
        fat_percentage:
          response.data.fat || response.data.fat_percentage
            ? response.data.fat_percentage || 0
            : 20,
      };

      console.log('Initial macros with defaults:', initialMacros);

      setMacros(initialMacros);
    } catch (error) {
      console.error('Error fetching client macros:', error);
      setError('Failed to fetch macros. Please try again.');
    }
  };

  console.log('Rendering macros:', macros);

  const updateClientMacros = async () => {
    try {
      setLoading(true);

      // Store the previous calories
      const previousCalories = macros.calories;

      // Calculate final macros before sending to backend
      const finalMacros = calculateMacros(macros, 'calories');

      // Include number_of_meals in the finalMacros object
      finalMacros.number_of_meals = macros.number_of_meals;

      // Update macros
      const response = await axiosInstance.post(
        `/client-macros/${clientId}/set_macros/`,
        finalMacros,
      );
      const updatedMacros = response.data;
      setMacros(updatedMacros);

      // Create note with macro update details
      const date = new Date().toLocaleString();
      const noteText =
        `Macros updated on ${date}\n` +
        `Calories: ${previousCalories} -> ${updatedMacros.calories}\n` +
        `Protein: ${updatedMacros.protein}g\n` +
        `Carbs: ${updatedMacros.carbs}g\n` +
        `Fat: ${updatedMacros.fat}g\n` +
        `Number of Meals: ${updatedMacros.number_of_meals}`;

      // Add the note
      await axiosInstance.post(`/clients/${clientId}/notes/`, {
        text: noteText,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error updating client macros:', error);
      setError('Failed to update macros. Please try again.');
      setLoading(false);
    }
  };

  const handleMacroChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'number_of_meals') {
      newValue = value === '' ? 0 : parseInt(value, 10);
    }
    setMacros((prevMacros) => ({
      ...prevMacros,
      [name]: newValue,
    }));
    setLastChangedField(name);
  };

  const handleMacroBlur = () => {
    setMacros((prevMacros) => {
      let updatedMacros;
      if (lastChangedField === 'calories') {
        updatedMacros = updateCalories({ ...prevMacros });
      } else {
        updatedMacros = calculateMacros({ ...prevMacros }, lastChangedField);
      }
      return updatedMacros;
    });
    setLastChangedField(null);
  };

  const toggleLockMacro = (macro) => {
    setLockedMacros((prevLockedMacros) => ({
      ...prevLockedMacros,
      [macro]: !prevLockedMacros[macro],
    }));
  };

  const updateCalories = (updatedMacros) => {
    let { calories, carbs_percentage, protein_percentage, fat_percentage } =
      updatedMacros;

    calories = calories === '' ? 0 : parseFloat(calories);
    carbs_percentage =
      carbs_percentage === '' ? 0 : parseFloat(carbs_percentage);
    protein_percentage =
      protein_percentage === '' ? 0 : parseFloat(protein_percentage);
    fat_percentage = fat_percentage === '' ? 0 : parseFloat(fat_percentage);

    const newCarbs = Math.round((calories * carbs_percentage) / 100 / 4);
    const newProtein = Math.round((calories * protein_percentage) / 100 / 4);
    const newFat = Math.round((calories * fat_percentage) / 100 / 9);

    return {
      ...updatedMacros,
      calories,
      carbs: newCarbs,
      protein: newProtein,
      fat: newFat,
      carbs_percentage,
      protein_percentage,
      fat_percentage,
    };
  };

  const calculateMacros = (updatedMacros, lastChangedField) => {
    let {
      calories,
      carbs,
      protein,
      fat,
      carbs_percentage,
      protein_percentage,
      fat_percentage,
      number_of_meals,
    } = updatedMacros;

    // Convert empty strings to 0 and ensure all values are numbers
    calories = calories === '' ? 0 : parseFloat(calories);
    carbs = carbs === '' ? 0 : parseFloat(carbs);
    protein = protein === '' ? 0 : parseFloat(protein);
    fat = fat === '' ? 0 : parseFloat(fat);
    carbs_percentage =
      carbs_percentage === '' ? 0 : parseFloat(carbs_percentage);
    protein_percentage =
      protein_percentage === '' ? 0 : parseFloat(protein_percentage);
    fat_percentage = fat_percentage === '' ? 0 : parseFloat(fat_percentage);
    number_of_meals =
      number_of_meals === '' ? 0 : parseInt(number_of_meals, 10);

    let totalCals = calories || carbs * 4 + protein * 4 + fat * 9;

    if (lastChangedField === 'calories') {
      // Recalculate macros based on new total calories
      if (!lockedMacros.carbs)
        carbs = Math.floor((totalCals * carbs_percentage) / 100 / 4);
      if (!lockedMacros.protein)
        protein = Math.floor((totalCals * protein_percentage) / 100 / 4);
      if (!lockedMacros.fat)
        fat = Math.floor((totalCals * fat_percentage) / 100 / 9);

      // Adjust for any remaining calories due to rounding
      let remainingCals = totalCals - (carbs * 4 + protein * 4 + fat * 9);
      while (remainingCals > 0) {
        if (!lockedMacros.carbs && remainingCals >= 4) {
          carbs++;
          remainingCals -= 4;
        } else if (!lockedMacros.protein && remainingCals >= 4) {
          protein++;
          remainingCals -= 4;
        } else if (!lockedMacros.fat && remainingCals >= 9) {
          fat++;
          remainingCals -= 9;
        } else break; // Can't perfectly distribute remaining calories
      }
    } else {
      // Recalculate total calories based on macros
      totalCals = carbs * 4 + protein * 4 + fat * 9;
    }

    // Recalculate percentages
    const totalMacroCals = carbs * 4 + protein * 4 + fat * 9;
    carbs_percentage = Math.round(((carbs * 4) / totalMacroCals) * 100) || 0;
    protein_percentage =
      Math.round(((protein * 4) / totalMacroCals) * 100) || 0;
    fat_percentage = 100 - carbs_percentage - protein_percentage; // Ensure percentages sum to 100

    return {
      calories: totalCals,
      carbs,
      protein,
      fat,
      carbs_percentage,
      protein_percentage,
      fat_percentage,
      number_of_meals,
    };
  };

  if (error) {
    return <div className="mt-2 text-sm text-red-600">{error}</div>;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">Meal Plan</h2>

      <div className="space-y-6">
        <div className="p-4 bg-slate-50 rounded-lg border border-slate-200">
          <MacroInput
            label="Calories"
            name="calories"
            value={macros.calories}
            onChange={handleMacroChange}
            onBlur={handleMacroBlur}
          />
        </div>

        <div className="grid gap-4">
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <MacroInput
              label="Protein"
              name="protein"
              value={macros.protein}
              percentage={macros.protein_percentage}
              locked={lockedMacros.protein}
              onChange={handleMacroChange}
              onBlur={handleMacroBlur}
              onLockToggle={() => toggleLockMacro('protein')}
            />
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <MacroInput
              label="Carbs"
              name="carbs"
              value={macros.carbs}
              percentage={macros.carbs_percentage}
              locked={lockedMacros.carbs}
              onChange={handleMacroChange}
              onBlur={handleMacroBlur}
              onLockToggle={() => toggleLockMacro('carbs')}
            />
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <MacroInput
              label="Fats"
              name="fat"
              value={macros.fat}
              percentage={macros.fat_percentage}
              locked={lockedMacros.fat}
              onChange={handleMacroChange}
              onBlur={handleMacroBlur}
              onLockToggle={() => toggleLockMacro('fat')}
            />
          </div>
        </div>

        <div className="p-4 bg-slate-50 rounded-lg border border-slate-200">
          <div className="flex items-center space-x-2">
            <div className="flex-1">
              <MacroInput
                label="Number of Meals"
                name="number_of_meals"
                value={
                  macros.number_of_meals === 0 ? '' : macros.number_of_meals
                }
                onChange={handleMacroChange}
                placeholder="0"
              />
            </div>
            <button
              data-tooltip-id="meals-tooltip"
              className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-600 transition-colors"
            >
              <Info className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <Tooltip id="meals-tooltip" place="right" className="max-w-sm">
        <div className="text-sm">
          <p className="font-medium mb-2">
            If set to 0, the backend will automatically determine the number of
            meals based on calorie intake. Leave at 0 unless discussed with a
            client.
          </p>
          <div className="mt-2">
            <p className="font-medium mb-1">Meal count based on calories:</p>
            <ul className="space-y-1">
              <li>• 1400-1800 calories: 3 meals</li>
              <li>• 1801-2500 calories: 4 meals</li>
              <li>• 2501-3200 calories: 5 meals</li>
              <li>• 3201+ calories: 6 meals</li>
            </ul>
          </div>
        </div>
      </Tooltip>

      <div className="mt-8 flex space-x-4">
        <button
          onClick={updateClientMacros}
          disabled={loading}
          className="flex items-center px-6 py-2.5 bg-gray-900 hover:bg-gray-800 disabled:bg-gray-300 text-white rounded-lg transition-colors duration-200 shadow-sm"
        >
          <Save className="w-4 h-4 mr-2" />
          {loading ? 'Updating...' : 'Update Macros'}
        </button>
        <button
          onClick={toggleBMRTDEE}
          className="flex items-center px-6 py-2.5 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors duration-200"
        >
          <Calculator className="w-4 h-4 mr-2" />
          BMR/TDEE Calculator
        </button>
      </div>

      {loading && (
        <div className="mt-4 flex items-center text-gray-500">
          <div className="animate-spin mr-2 h-4 w-4 border-2 border-gray-600 border-t-transparent rounded-full"></div>
          Updating macros...
        </div>
      )}

      {showBMRTDEE && (
        <div className="mt-6 border-t border-gray-200 pt-6">
          <BMRTDEECalculator clientId={clientId} onClose={toggleBMRTDEE} />
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
};

export default MealPlan;
