import React from 'react';

const ClientProfile = ({ client }) => {
  const profileFields = [
    { label: 'Email', value: client.email },
    { label: 'Phone', value: client.phone },
    { label: 'Address', value: client.address },
    { label: 'Start Date', value: client.start_date },
    { label: 'Product', value: client.product },
    { label: 'Monthly Price', value: client.monthly_price ? `$${client.monthly_price}` : 'Not set' },
  ];

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Client Profile</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and information.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          {profileFields.map((field, index) => (
            <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {field.value || 'Not provided'}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ClientProfile;