import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance } from '../axiosConfig';

const WorkoutSearchDropdown = ({ onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [allWorkouts, setAllWorkouts] = useState([]);
  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newWorkout, setNewWorkout] = useState({
    title: '',
    body_part: '',
    equipment: '',
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchAllWorkouts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/fetch-workouts/');
        const workoutsData = Array.isArray(response.data)
          ? response.data
          : response.data.workouts || response.data.results || [];
        setAllWorkouts(workoutsData);
        setFilteredWorkouts(workoutsData);
      } catch (err) {
        setError('Failed to fetch workouts');
        console.error('Error fetching workouts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllWorkouts();
  }, []);

  useEffect(() => {
    const filterWorkouts = () => {
      if (!searchTerm.trim()) {
        setFilteredWorkouts(allWorkouts);
        return;
      }

      const searchTermLower = searchTerm.toLowerCase();

      const exactMatches = [];
      const partialMatches = [];

      allWorkouts.forEach((workout) => {
        const titleLower = workout.title.toLowerCase();
        if (titleLower === searchTermLower) {
          exactMatches.push(workout);
        } else if (titleLower.includes(searchTermLower)) {
          partialMatches.push(workout);
        }
      });

      // Sort partial matches by relevance
      partialMatches.sort((a, b) => {
        const aIndex = a.title.toLowerCase().indexOf(searchTermLower);
        const bIndex = b.title.toLowerCase().indexOf(searchTermLower);
        if (aIndex !== bIndex) return aIndex - bIndex;
        return a.title.length - b.title.length;
      });

      setFilteredWorkouts([...exactMatches, ...partialMatches]);
    };

    const debounceTimer = setTimeout(filterWorkouts, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, allWorkouts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleAddNewWorkout = async () => {
    setLoading(true);
    setError(null);
    try {
      // Check if the workout already exists
      const existingWorkout = allWorkouts.find(
        (w) => w.title.toLowerCase() === newWorkout.title.toLowerCase(),
      );
      if (existingWorkout) {
        setError('This workout already exists');
        return;
      }

      const response = await axiosInstance.post(
        '/add-workout/',
        JSON.stringify(newWorkout),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      onSelect(response.data);
    } catch (err) {
      setError('Failed to add new workout');
      console.error('Error adding new workout:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="absolute z-50 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg"
    >
      <input
        type="text"
        className="w-full p-2 border-b"
        placeholder="Search for a workout..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setNewWorkout({ ...newWorkout, title: e.target.value });
        }}
        autoFocus
      />
      {loading && <div className="p-2">Loading...</div>}
      {error && <div className="p-2 text-red-500">{error}</div>}
      <ul className="max-h-60 overflow-y-auto">
        {filteredWorkouts.map((workout) => (
          <li
            key={workout.id}
            className="p-2 hover:bg-gray-100 cursor-pointer"
            onClick={() => onSelect(workout)}
          >
            {workout.title}
          </li>
        ))}
      </ul>
      {filteredWorkouts.length === 0 && (
        <div className="p-2 border-t">
          <p className="font-semibold">Add new workout:</p>
          <input
            type="text"
            className="w-full p-2 mt-2 border rounded"
            placeholder="Body part"
            value={newWorkout.body_part}
            onChange={(e) =>
              setNewWorkout({ ...newWorkout, body_part: e.target.value })
            }
          />
          <input
            type="text"
            className="w-full p-2 mt-2 border rounded"
            placeholder="Equipment"
            value={newWorkout.equipment}
            onChange={(e) =>
              setNewWorkout({ ...newWorkout, equipment: e.target.value })
            }
          />
          <button
            className="w-full p-2 mt-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleAddNewWorkout}
          >
            Add Workout
          </button>
        </div>
      )}
    </div>
  );
};

export default WorkoutSearchDropdown;
