// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authInstance } from '../../axiosConfig';  

// Thunk for logging in
export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    const response = await authInstance.post('/login/', credentials);
    console.log('API Response:', response.data);
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('refresh', response.data.refresh_token);
    return response.data;
  } catch (error) {
    console.log('API Error:', error.response?.data);
    return thunkAPI.rejectWithValue(error.response?.data || 'An error occurred');
  }
});


// Thunk for refreshing token
export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, thunkAPI) => {
  try {
    const refresh = localStorage.getItem('refresh');
    const response = await authInstance.post('/token/refresh/', { refresh });
    console.log('Refresh Token Response:', response.data);
    localStorage.setItem('token', response.data.access);
    return response.data;
  } catch (error) {
    console.log('Refresh Token Error:', error.response?.data);
    return thunkAPI.rejectWithValue(error.response?.data || 'An error occurred');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log('Login Pending');
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = { id: action.payload.user_id, type: action.payload.user_type };
        state.token = action.payload.access_token;
        localStorage.setItem('token', action.payload.access_token);
        localStorage.setItem('refresh', action.payload.refresh_token);
        localStorage.setItem('user', JSON.stringify(state.user));
        console.log('Login Successful:', action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log('Login Failed:', action.payload);
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.access;
        console.log('Token refreshed successfully');
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.error = action.payload;
        console.log('Token refresh failed:', action.payload);
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
