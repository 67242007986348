import { axiosInstance } from '../../axiosConfig';

const CustomFilterService = {
  getAllFilters: async () => {
    const response = await axiosInstance.get('/coach-dashboard/custom-filters/');
    return response.data;
  },

  getFilter: async (id) => {
    const response = await axiosInstance.get(`/coach-dashboard/custom-filters/${id}/`);
    return response.data;
  },

  createFilter: async (filterData) => {
    const response = await axiosInstance.post('/coach-dashboard/custom-filters/', filterData);
    return response.data;
  },

  updateFilter: async (id, filterData) => {
    const response = await axiosInstance.put(`/coach-dashboard/custom-filters/${id}/`, filterData);
    return response.data;
  },

  deleteFilter: async (id) => {
    await axiosInstance.delete(`/coach-dashboard/custom-filters/${id}/`);
  },

  removeFilterFromClient: async (filterId, clientId) => {
    const response = await axiosInstance.post(`/coach-dashboard/custom-filters/${filterId}/remove_from_client/`, { client_id: clientId });
    return response.data;
  },

  assignFilterToClient: async (filterId, clientId) => {
    const response = await axiosInstance.post(`/coach-dashboard/custom-filters/${filterId}/assign_to_client/`, { client_id: clientId });
    return response.data;
  },

  getFiltersForClient: async (clientId) => {
      const response = await axiosInstance.get(`/coach-dashboard/custom-filters/filters_for_client/`, {
      params: { client_id: clientId }
    });
    return response.data;
  },

  getClientsWithFilter: async (filterId) => {
    const response = await axiosInstance.get(`/coach-dashboard/custom-filters/${filterId}/clients_with_filter/`);
    return response.data;
  }
}; 



export default CustomFilterService;