import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import WorkoutSearchDropdown from './WorkoutSearchDropdown';
import {
  ChevronDown,
  ChevronUp,
  X,
  ArrowUp,
  ArrowDown,
  Clock,
  Dumbbell,
  Gauge,
  Activity,
} from 'lucide-react';

const EditWorkoutPlanPopup = ({ plan, onClose, onSave, clientId }) => {
  const [editedPlan, setEditedPlan] = useState(plan);
  const [activeSearchDropdown, setActiveSearchDropdown] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [expandedSessions, setExpandedSessions] = useState({});
  const centerX = Math.max(0, (window.innerWidth - 800) / 2);
  const centerY = Math.max(0, (window.innerHeight - 900) / 2);

  const handleInputChange = (e, field) => {
    setEditedPlan({ ...editedPlan, [field]: e.target.value });
  };

  const handleSessionChange = (sessionIndex, field, value) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex] = {
      ...newSessions[sessionIndex],
      [field]: value,
    };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleMovementChange = (sessionIndex, movementIndex, field, value) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements[movementIndex] = {
      ...newSessions[sessionIndex].movements[movementIndex],
      [field]: value,
    };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleDeleteMovement = (sessionIndex, movementIndex) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements.splice(movementIndex, 1);
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleWorkoutSelect = (
    sessionIndex,
    movementIndex,
    selectedWorkout,
  ) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements[movementIndex] = {
      ...newSessions[sessionIndex].movements[movementIndex],
      title: selectedWorkout.title,
      id: selectedWorkout.id,
      video_url: selectedWorkout.video_url || null,
    };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
    setActiveSearchDropdown(null);
  };

  const handleAddMovement = (sessionIndex) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements.push({
      id: Date.now(), // Temporary ID
      title: 'New Movement',
      prescribed_sets: 1,
      prescribed_reps: 1,
      prescribed_rest: '',
      notes: '', // Added notes field
    });
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleAddSession = () => {
    const newSessions = [...editedPlan.sessions];
    newSessions.push({
      id: Date.now(),
      day: newSessions.length + 1,
      coaches_intro_note: 'New Session',
      approx_time: '30 minutes',
      difficulty_level: 'Beginner',
      tempo: 'Slow and Controlled',
      best_practice: 'Text me with any questions.',
      movements: [],
    });
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await onSave(editedPlan);
      onClose();
    } catch (err) {
      setError('Failed to save the workout plan. Please try again.');
      console.error('Error saving workout plan:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const moveSession = (index, direction) => {
    const newSessions = [...editedPlan.sessions];
    const newIndex = index + direction;

    if (newIndex < 0 || newIndex >= newSessions.length) return;

    const [movedSession] = newSessions.splice(index, 1);
    newSessions.splice(newIndex, 0, movedSession);

    newSessions.forEach((session, idx) => {
      session.day = idx + 1;
    });

    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const toggleSession = (sessionId) => {
    setExpandedSessions((prev) => ({
      ...prev,
      [sessionId]: !prev[sessionId],
    }));
  };

  const getDifficultyColor = (level) => {
    const levels = {
      Beginner: 'bg-green-100 text-green-800',
      Intermediate: 'bg-yellow-100 text-yellow-800',
      Advanced: 'bg-red-100 text-red-800',
    };
    return levels[level] || 'bg-gray-100 text-gray-800';
  };

  return (
    <Rnd
      default={{
        x: centerX,
        y: centerY,
        width: 800,
        height: 900,
      }}
      minWidth={600}
      minHeight={600}
      bounds="window"
      className="fixed z-50 bg-white shadow-xl rounded-xl overflow-hidden"
    >
      <div className="h-full flex flex-col">
        <div className="border-b bg-gray-50/80 p-4">
          <div className="flex justify-between items-center">
            <input
              className="text-2xl font-bold bg-transparent border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded px-2 py-1 w-96"
              value={editedPlan.title}
              onChange={(e) => handleInputChange(e, 'title')}
            />
            <div className="flex gap-2">
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
              <button
                onClick={onClose}
                className="p-2 text-gray-500 hover:text-gray-700 rounded-lg hover:bg-gray-100"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {error && (
          <div className="m-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        <div className="flex-grow overflow-y-auto p-6">
          <textarea
            className="w-full p-3 mb-6 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
            value={editedPlan.description}
            onChange={(e) => handleInputChange(e, 'description')}
            placeholder="Add workout plan description..."
            rows={3}
          />

          <div className="space-y-4">
            {editedPlan.sessions.map((session, sessionIndex) => (
              <div
                key={session.id}
                className="bg-white rounded-lg border shadow-sm"
              >
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4 flex-1">
                      <div className="flex flex-col gap-1">
                        <button
                          onClick={() => moveSession(sessionIndex, -1)}
                          disabled={sessionIndex === 0}
                          className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50 rounded hover:bg-gray-100"
                        >
                          <ArrowUp className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => moveSession(sessionIndex, 1)}
                          disabled={
                            sessionIndex === editedPlan.sessions.length - 1
                          }
                          className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50 rounded hover:bg-gray-100"
                        >
                          <ArrowDown className="w-4 h-4" />
                        </button>
                      </div>
                      <div className="flex-1">
                        <input
                          className="text-lg font-medium w-full border-0 focus:ring-2 focus:ring-blue-500 rounded px-2 py-1"
                          value={`Day ${session.day}: ${session.coaches_intro_note}`}
                          onChange={(e) =>
                            handleSessionChange(
                              sessionIndex,
                              'coaches_intro_note',
                              e.target.value.split(': ')[1],
                            )
                          }
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => toggleSession(session.id)}
                      className="p-2 hover:bg-gray-100 rounded-lg"
                    >
                      {expandedSessions[session.id] ? (
                        <ChevronUp className="w-5 h-5" />
                      ) : (
                        <ChevronDown className="w-5 h-5" />
                      )}
                    </button>
                  </div>

                  {expandedSessions[session.id] && (
                    <div className="mt-4 space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center gap-2">
                          <Clock className="w-4 h-4 text-gray-500" />
                          <input
                            className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={session.approx_time}
                            onChange={(e) =>
                              handleSessionChange(
                                sessionIndex,
                                'approx_time',
                                e.target.value,
                              )
                            }
                            placeholder="Approximate time"
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <Gauge className="w-4 h-4 text-gray-500" />
                          <input
                            className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={session.difficulty_level}
                            onChange={(e) =>
                              handleSessionChange(
                                sessionIndex,
                                'difficulty_level',
                                e.target.value,
                              )
                            }
                            placeholder="Difficulty level"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center gap-2">
                          <Activity className="w-4 h-4 text-gray-500" />
                          <input
                            className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={session.tempo}
                            onChange={(e) =>
                              handleSessionChange(
                                sessionIndex,
                                'tempo',
                                e.target.value,
                              )
                            }
                            placeholder="Tempo"
                          />
                        </div>
                      </div>

                      <textarea
                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                        value={session.best_practice}
                        onChange={(e) =>
                          handleSessionChange(
                            sessionIndex,
                            'best_practice',
                            e.target.value,
                          )
                        }
                        placeholder="Best practices and notes"
                        rows={2}
                      />

                      <div>
                        <div className="flex items-center justify-between mb-2">
                          <h4 className="font-medium text-gray-700">
                            Movements
                          </h4>
                          <button
                            onClick={() => handleAddMovement(sessionIndex)}
                            className="px-3 py-1 text-sm border border-blue-600 text-blue-600 hover:bg-blue-50 rounded-lg"
                          >
                            Add Movement
                          </button>
                        </div>

                        <div className="space-y-3">
                          {session.movements.map((movement, movementIndex) => (
                            <div
                              key={movement.id}
                              className="bg-gray-50 rounded-lg border p-3"
                            >
                              <div className="flex items-center justify-between mb-2">
                                <button
                                  className="flex items-center font-medium text-blue-600 hover:text-blue-700"
                                  onClick={() =>
                                    setActiveSearchDropdown(
                                      `${sessionIndex}-${movementIndex}`,
                                    )
                                  }
                                >
                                  <Dumbbell className="w-4 h-4 mr-2" />
                                  {movement.title}
                                </button>
                                <button
                                  onClick={() =>
                                    handleDeleteMovement(
                                      sessionIndex,
                                      movementIndex,
                                    )
                                  }
                                  className="p-1 text-red-500 hover:text-red-700 rounded hover:bg-red-50"
                                >
                                  <X className="w-4 h-4" />
                                </button>
                              </div>

                              {activeSearchDropdown ===
                                `${sessionIndex}-${movementIndex}` && (
                                <div className="relative z-50">
                                  <WorkoutSearchDropdown
                                    onSelect={(workout) =>
                                      handleWorkoutSelect(
                                        sessionIndex,
                                        movementIndex,
                                        workout,
                                      )
                                    }
                                    onClose={() =>
                                      setActiveSearchDropdown(null)
                                    }
                                  />
                                </div>
                              )}

                              <div className="space-y-4">
                                <div className="flex items-center gap-2">
                                  <input
                                    className="w-16 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    type="text"
                                    value={movement.prescribed_sets}
                                    onChange={(e) =>
                                      handleMovementChange(
                                        sessionIndex,
                                        movementIndex,
                                        'prescribed_sets',
                                        e.target.value,
                                      )
                                    }
                                  />
                                  <span className="text-gray-600">sets of</span>
                                  <input
                                    className="w-16 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    type="text"
                                    value={movement.prescribed_reps}
                                    onChange={(e) =>
                                      handleMovementChange(
                                        sessionIndex,
                                        movementIndex,
                                        'prescribed_reps',
                                        e.target.value,
                                      )
                                    }
                                  />
                                  <span className="text-gray-600">reps</span>
                                  <input
                                    className="w-32 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    type="text"
                                    placeholder="Rest"
                                    value={movement.prescribed_rest || ''}
                                    onChange={(e) =>
                                      handleMovementChange(
                                        sessionIndex,
                                        movementIndex,
                                        'prescribed_rest',
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>

                                <div>
                                  <label className="block text-sm text-gray-600 mb-1">
                                    Notes
                                  </label>
                                  <textarea
                                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                                    rows="2"
                                    value={movement.notes || ''}
                                    onChange={(e) =>
                                      handleMovementChange(
                                        sessionIndex,
                                        movementIndex,
                                        'notes',
                                        e.target.value,
                                      )
                                    }
                                    placeholder="Movement notes..."
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleAddSession}
            className="mt-6 w-full py-3 bg-purple-500 hover:bg-purple-600 text-white rounded-lg font-medium"
          >
            Add New Session
          </button>
        </div>
      </div>
    </Rnd>
  );
};

export default EditWorkoutPlanPopup;
