import axios from 'axios';
import store from './redux/store';
import { refreshToken, logout } from './redux/slices/authSlice';


const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? '' // Empty string for development to use proxy
  : 'https://api.trainupapi.app'; // Actual URL for production

export const authInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const csrfInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

let csrfToken = null;
let isRefreshing = false;
let refreshSubscribers = [];

async function fetchCsrfToken() {
  try {
    const response = await axiosInstance.get('/csrf/');
    csrfToken = response.data.csrfToken;
    console.log('CSRF token fetched:', csrfToken);
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
}

function needsCsrfToken(url, method) {
  const csrfMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];
  const csrfUrls = ['/messages/send/'];

  return csrfMethods.includes(method.toUpperCase()) && csrfUrls.some(csrfUrl => url.includes(csrfUrl));
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
}

[axiosInstance, csrfInstance].forEach(instance => {
  instance.interceptors.request.use(
    async config => {
      console.log('Request URL:', config.url);
      console.log('Request method:', config.method);

      if (!config.url.endsWith('/login/') && !config.url.endsWith('/token/refresh/')) {
        const state = store.getState();
        const token = state.auth.token;
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
          console.log('Authorization token added to request:', token);
        } else {
          console.warn('No authorization token found in Redux state');
        }
      }

      if (needsCsrfToken(config.url, config.method)) {
        if (!csrfToken) {
          await fetchCsrfToken();
        }
        if (csrfToken) {
          config.headers['X-CSRFToken'] = csrfToken;
          console.log('CSRF token added to request:', csrfToken);
        } else {
          console.warn('CSRF token required but not available');
        }
      }

      console.log('Final request headers:', config.headers);
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(resolve => {
            subscribeTokenRefresh(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(axios(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const result = await store.dispatch(refreshToken());
          const newToken = result.payload.access;
          instance.defaults.headers['Authorization'] = 'Bearer ' + newToken;
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          onRefreshed(newToken);
          isRefreshing = false;
          return instance(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          isRefreshing = false;
          store.dispatch(logout());
          // You might want to implement redirection to login page here
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
});

csrfInstance.defaults.withCredentials = true;

fetchCsrfToken();

export default axiosInstance;