import React from 'react';
import { ReactComponent as LockIcon } from '../svg/lock.svg';
import { ReactComponent as UnlockIcon } from '../svg/unlock.svg';

const MacroInput = ({ label, name, value, percentage, locked, onChange, onBlur, onLockToggle }) => {
  const isNumberOfMeals = name === 'number_of_meals';
  
  return (
    <div className="flex items-center space-x-2">
      <label className="block text-sm font-medium text-gray-700 w-32">{label}</label>
      <input
        type="number"
        name={name}
        value={isNumberOfMeals && value === 0 ? '' : value}
        onChange={onChange}
        onBlur={onBlur}
        className="block w-1/3 px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        placeholder={isNumberOfMeals ? "0" : "grams"}
      />
      {percentage !== undefined && (
        <input
          type="number"
          name={`${name}_percentage`}
          value={percentage}
          onChange={onChange}
          onBlur={onBlur}
          className="block w-1/3 px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="%"
        />
      )}
      {onLockToggle && (
        <button
          onClick={onLockToggle}
          className="p-1"
          aria-label={locked ? `Unlock ${label}` : `Lock ${label}`}
        >
          {locked ? <LockIcon className="h-5 w-5" /> : <UnlockIcon className="h-5 w-5" />}
        </button>
      )}
    </div>
  );
};

export default MacroInput;